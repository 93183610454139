import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import { getCourseByAdmissionIdResponse } from "./course.model";

class CourseService {
  ENDPOINT = config.baseApiApplication + "/course";
  public getCourseByAdmissionId = async (
    admission_id: number
  ): Promise<IApiResponse<getCourseByAdmissionIdResponse[]>> => {
    const url = `${this.ENDPOINT}/${admission_id}/courses`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };
}

export default new CourseService();
