import { RootState } from "../app.model";
import {
  getStudentAdmissionDetailsById,
  searchStudentData,
} from "./student.action";
import { IStudentState } from "./student.model";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: IStudentState = {
  studentData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const studentSlice = createSlice({
  name: "student",
  initialState,
  reducers: {
    clearState: (state) => {
      state.studentData.data = initialState.studentData.data;
      state.getById.data = initialState.getById.data;
      state.studentData.message = '';
    },
  },
  extraReducers: {
    // search studentData
    [searchStudentData.pending.type]: (state) => {
      state.studentData.loading = true;
    },
    [searchStudentData.fulfilled.type]: (state, action) => {
      state.studentData.data = action.payload.data;
      state.studentData.message = action.payload.message;
      state.studentData.loading = false;
      state.studentData.hasErrors = false;
    },
    [searchStudentData.rejected.type]: (state, action) => {
      state.studentData.loading = false;
      state.studentData.hasErrors = true;
      state.studentData.message = action.error.message;
    },

    // getById admissionDetails
    [getStudentAdmissionDetailsById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getStudentAdmissionDetailsById.fulfilled.type]: (state, action) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getStudentAdmissionDetailsById.rejected.type]: (state, action) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },
  },
});

export const studentSelector = (state: RootState) => state.student;

export const { clearState } = studentSlice.actions;

// The reducer
export default studentSlice.reducer;
