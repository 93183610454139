import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import { IStudentList } from "./student.model";

class StudentService {
  ENDPOINT = config.baseApiApplication + "/students";

  public searchStudentData = async (): Promise<
    IApiResponse<IStudentList[]>
  > => {
    const url = `${this.ENDPOINT}/student-list`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public getStudentAdmissionDetailsById = async (
    id: number
  ): Promise<IApiResponse<IStudentList>> => {
    const url = `${this.ENDPOINT}/${id}/student-details`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };
}

export default new StudentService();
