import { createAsyncThunk } from "@reduxjs/toolkit";
import studentService from "src/services/student/student.service";

export const searchStudentData = createAsyncThunk(
  "student/searchStudentData",
  async () => {
    return studentService.searchStudentData();
  }
);

export const getStudentAdmissionDetailsById = createAsyncThunk(
  "student/getStudentAdmissionDetailsById",
  async (id: number) => {
    return studentService.getStudentAdmissionDetailsById(id);
  }
);
