import { createSlice } from "@reduxjs/toolkit";
import { getCourseByAdmissionId } from "./course.action";
import { RootState } from "../app.model";
import { ICourseState } from "./course.model";

export const initialState: ICourseState = {
  getCourseByAdmissionId: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    clearState: (state) => {
      state.getCourseByAdmissionId.data =
        initialState.getCourseByAdmissionId.data;
    },
  },
  extraReducers: {
    [getCourseByAdmissionId.pending.type]: (state) => {
      state.getCourseByAdmissionId.loading = true;
    },
    [getCourseByAdmissionId.fulfilled.type]: (state, action) => {
      state.getCourseByAdmissionId.data = action.payload.data;
      state.getCourseByAdmissionId.message = action.payload.message;
      state.getCourseByAdmissionId.loading = false;
      state.getCourseByAdmissionId.hasErrors = false;
    },
    [getCourseByAdmissionId.rejected.type]: (state, action) => {
      state.getCourseByAdmissionId.loading = false;
      state.getCourseByAdmissionId.hasErrors = true;
      state.getCourseByAdmissionId.message = action.error.message;
    },
  },
});

// A selector
export const courseSelector = (state: RootState) => state.course;

export const { clearState } = courseSlice.actions;

// The reducer
export default courseSlice.reducer;
