import { Modal } from "antd";
import { IModalProps } from './Modal.model';

const ModalComponent = (props: IModalProps) => {
  const { title, showModal, onCancel, component, footer, className, width } = props;
  return (
    <>
      <Modal
        className={className}
        width={width}
        title={title}
        centered
        keyboard={true}
        visible={showModal}
        onCancel={onCancel}
        footer={footer}
      >
        {component}
      </Modal>
    </>
  );
};
export default ModalComponent;
