import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import { IExamPaperAnswer, IExamPaperDetails, IExamPaperQuestionAnswer, IExamPaperStudentAnswer, ISubmitExamPaperStudentAnswer } from "./exam.model";

class ExamService {
  ENDPOINT = config.baseApiApplication + "/exam";

  public getExamPaperDetails = async (
    admissionId: number,
    batchShingingSheetId: number
  ): Promise<IApiResponse<IExamPaperDetails>> => {
    const url = `${this.ENDPOINT}/${admissionId}/exam-papers/${batchShingingSheetId}`;
    return request({
      url,
      method: "GET",
      params: {},
    }).then((res) => {
      return res.data;
    });
  };

  public getExamPaperDetailsByExamPaperId = async (
    examPaperId: number,
    admissionId: number
  ): Promise<IApiResponse<IExamPaperDetails>> => {
    const url = `${this.ENDPOINT}/exam-paper/${examPaperId}/${admissionId}`;
    return request({
      url,
      method: "GET",
      params: {},
    }).then((res) => {
      return res.data;
    });
  }

  public AddExamPaperStudentAnswer = async (
    data: IExamPaperStudentAnswer
  ): Promise<IApiResponse<IExamPaperAnswer>> => {
    const url = `${this.ENDPOINT}/exam-paper-student-answer`;
    return request({
      url,
      method: "POST",
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data,
    }).then((res) => {
      return res.data;
    });
  }

  public submitExamPaperStudentAnswer = async (
    data: ISubmitExamPaperStudentAnswer
  ): Promise<IApiResponse<IExamPaperQuestionAnswer>> => {
    const url = `${this.ENDPOINT}/submit-exam-paper-student-answer`;
    return request({
      url,
      method: "POST",
      data
    }).then((res) => {
      return res.data;
    });
  }

  public getExamResultDetails = async (
    data: {admission_id: number, package_id : number}
  ): Promise<IApiResponse<{admission_id: number, package_id : number}>> => {
    const url = `${this.ENDPOINT}/result/${data.admission_id}/${data.package_id}`;
    return request({ url, method: "GET", params: {} }).then((res) => {
      return res.data;
    });
  };
}

export default new ExamService();
