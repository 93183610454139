import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISetMpin, IVerify } from "src/components/OtpInput/otpinput.model";
import { INumber } from "src/pages/Login/login.model";
import loginService from "src/services/login/login.service";

export const sendOtp = createAsyncThunk(
    "login/sendOtp",
    async (data: INumber) => {
      return loginService.sendOtp(data);
    }
  );

export const reSendOtp = createAsyncThunk(
    "login/reSendOtp",
    async (data: INumber) => {
      return loginService.reSendOtp(data);
    }
  );

export const verifyOtp = createAsyncThunk(
    "login/verifyOtp",
    async (data: IVerify) => {
      return loginService.verifyOtp(data);
    }
  );

export const setStudentMpin = createAsyncThunk(
    "login/setStudentMpin",
    async (data: ISetMpin) => {
      return loginService.setStudentMpin(data);
    }
  );
