import { useState } from 'react';

export interface ServiceFunction {
    (...args: any[]): Promise<any>;
}

export interface HookResult {
    loading: boolean;
    data: any;
    error: boolean;
    refetch: (...args: any[]) => Promise<any>;
}

const useLazyService = (service: ServiceFunction, ...params: any[]): HookResult => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<any>(null);
    const [data, setData] = useState<any>(null);

    const fetchApi = async (p: any[]) => {
        setError(null);
        setLoading(true);

        try {
            const response = await service(...p);
            setData(response);
            return response;
        } catch (error) {
            setError(error);
            return error;
        } finally {
            setLoading(false);
        }
    };

    const refetch = (...p: any[]) => {
        if (p && p.length > 0) {
            return fetchApi(p);
        } else {
            return fetchApi(params);
        }
    };

    return { loading, data, error, refetch };
};

export default useLazyService;
