import { Button, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import ContainerHeader from "src/components/ContainerHeader";
import FloatLabel from "src/components/Form/FloatLabel";
import { ISubmissionFormProps } from "./SubmissionForm.model";

const SubmissionForm = (props: ISubmissionFormProps) => {
  const { onFinish, initialValue ,setIsSubmissionModel } = props;
  const [form] = Form.useForm();

  const rules = {
    submission_link: [
      { required: true, message: "Please Enter Submission Link" },
      {
        pattern: new RegExp(
          "^" +
            "(?:(?:https?|http)://)" +
            "(?:\\S+(?::\\S*)?@)?" +
            "(?:" +
              "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
              "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
              "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
              "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
              "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
              "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
            "|" +
              "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
              "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
              "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
            ")" +
            "(?::\\d{2,5})?" +
            "(?:/\\S*)?" + 
            "$", "i" ),
        message: "Invalid URL format",
      },
    ],
  };

  const handleCancel = () => {
    setIsSubmissionModel(false);
    form.resetFields();
  };

  return (
    <div>
      <Form 
        onFinish={onFinish}
        className="gx-py-0"
        initialValues={initialValue}
        form={form}
      >
        <ContainerHeader
          title={"Enter Submission Link"}
        />

        <FloatLabel
          label="Submission Link"
          placeholder="Enter Submission Link"
          name="submission_link"
          required

        >
          <Form.Item
            name="submission_link"
            rules={rules.submission_link}
          >
            <TextArea style={{ height: 100 }} />
          </Form.Item>
        </FloatLabel>

        <Form.Item className="gx-text-right gx-mr-3">
          
        <Button type="default" className="gx-mb-0" onClick={handleCancel}>
              Cancel
            </Button>
          <Button type="primary" className="gx-mb-0" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SubmissionForm;
function setIsModalOpen(arg0: boolean) {
  throw new Error("Function not implemented.");
}

