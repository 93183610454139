import { useEffect, useRef } from "react";
import { Form, Input, Button } from "antd";
import { IProps, IVerify } from "./otpinput.model";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { verifyOtp } from "src/state/Login/login.action";

const OtpInput = (props: IProps) => {
  const { mobileNumber } = props;
  const formRef = useRef<any>();
  const dispatch = useDispatch<AppDispatch>();

  const rules = {
    otp: [
      { message: "Please Enter OTP" },
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
  };

  const handleSubmit = (values: { otp: string[] }) => {
    const codeValue = values.otp.join("");
    const verifyOtpPayload: IVerify = {
      mobile_no: mobileNumber?.mobile_no,
      otp: codeValue,
      device_token: "string",
      type: "WEB",
    };
    dispatch(verifyOtp(verifyOtpPayload))
  };

  const handleInputChange = (inputIndex: number, value: string) => {
    const fieldName = ["otp", inputIndex];
    formRef.current.setFieldsValue({ [fieldName as any]: value });

    if (value && inputIndex < 5) {
      const nextFieldName = ["otp", inputIndex + 1];
      const nextInput = formRef.current.getFieldInstance(nextFieldName);
      nextInput.focus();
    } else if (!value && inputIndex > 0) {
      const prevFieldName = ["otp", inputIndex - 1];
      const prevInput = formRef.current.getFieldInstance(prevFieldName);
      prevInput.focus();
    }
  };

  const handleKeyDown = (
    inputIndex: number,
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === "Backspace") {
      const value = (e.target as HTMLInputElement).value;
      if (!value && inputIndex > 0) {
        e.preventDefault();
        const prevFieldName = ["otp", inputIndex - 1];
        const prevInput = formRef.current?.getFieldInstance(prevFieldName);
        prevInput?.focus();
      } else if (value && inputIndex === 0) {
        e.preventDefault();
        formRef.current?.setFieldsValue({ ["otp"]: [] });
        const firstInput = formRef.current?.getFieldInstance(["otp", 0]);
        firstInput?.focus();
      }
    }
  };

  useEffect(() => {
    const firstInput = formRef.current?.getFieldInstance(['otp', 0]);
    const value = formRef.current?.getFieldValue(['otp', 0]);
    if (!value) {
      firstInput?.focus();
    }
  }, []);
  
  return (
    <Form ref={formRef} onFinish={handleSubmit} className="otp-input gx-py-0">
      <Form.Item name="otp">
        <Input.Group compact>
          {[...Array(6)].map((_, index) => (
            <Form.Item
              key={index}
              name={["otp", index]}
              noStyle
              rules={rules.otp}
            >
              <Input
              className="otp-number"
                maxLength={1}
                style={{ width: "40px", marginRight: "5px" }}
                onChange={(e) => handleInputChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(index, e)}
              />
            </Form.Item>
          ))}
        </Input.Group>
      </Form.Item>
      <Form.Item>
        <Button type="primary" className="otp-submit" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default OtpInput;
