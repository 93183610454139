import { useContext } from "react";
import { Layout, Popover } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../../utils/constants/ThemeSetting";
import { toggleCollapsedSideNav } from "../../../state/setting/setting.action";
import MailNotification from "../../MailNotification";
import AppNotification from "../../AppNotification";
import { SidebarCollapseContext } from "src/contexts/SidebarCollapseContext";
import { AppDispatch } from "src/state/app.model";
import UserInfo from "../../UserInfo";
import UserProfile from "../Slidebar/UserProfile";
import SidebarLogo from "../Slidebar/SidebarLogo";

const { Header } = Layout;

const Topbar = () => {
  const sidebarCollapse = useContext(SidebarCollapseContext);
  const { navStyle } = useSelector(({ settings }: any) => settings);
  const navCollapsed = useSelector(({ common }: any) => common.navCollapsed);
  const width = useSelector(({ common }: any) => common.width);
  const dispatch = useDispatch<AppDispatch>();
  return (
    <Header>
       <SidebarLogo />


      <ul className="gx-header-notifications gx-ml-auto gx-d-lg-none">
        {width >= TAB_SIZE ? null : (
          <>
            <li className="gx-notify">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<AppNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-d-none">
                  <i className="icon icon-notification" />
                </span>
              </Popover>
            </li>

            <li className="gx-msg">
              <Popover
                overlayClassName="gx-popover-horizantal"
                placement="bottomRight"
                content={<MailNotification />}
                trigger="click"
              >
                <span className="gx-pointer gx-status-pos gx-d-none">
                  <i className="icon icon-chat-new" />
                  <span className="gx-status gx-status-rtl gx-small gx-orange" />
                </span>
              </Popover>
            </li>
          </>
        )}
    
      </ul>
      <UserProfile />
    </Header>
  );
};

export default Topbar;
