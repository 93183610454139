import { createSlice } from "@reduxjs/toolkit";
import { reSendOtp, sendOtp, setStudentMpin, verifyOtp } from "./login.action";
import { ILoginState } from "./login.model";
import { RootState } from "../app.model";

export const initialState: ILoginState = {
  OtpData: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  ResendOtpData: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  verifyData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      mobile_no: "",
      id: 0,
      token: "",
      type: "",
      device_token: "",
      created_date: "",
    },
  },
  setMpinData: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  isLogIn: false,
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.OtpData.message = "";
      state.verifyData.message = "";
      state.ResendOtpData.message = "";
      state.setMpinData.message = "";
    },
    setIsLogIn: (state, action) => {
      state.isLogIn = action.payload;
      if (!action.payload) {
        state.verifyData.data = {
          mobile_no: "",
          id: 0,
          token: "",
          type: "",
          device_token: "",
          created_date: "",
        };
      }
    },
    setTokenData: (state, action) => {
      state.verifyData.data = action.payload;
    },
  },
  extraReducers: {
    //send otp on mobile no
    [sendOtp.pending.type]: (state) => {
      state.OtpData.loading = true;
    },
    [sendOtp.fulfilled.type]: (state, action) => {
      state.OtpData.message = action.payload.message;
      state.OtpData.hasErrors = action.payload.error;
      state.OtpData.loading = false;
      state.OtpData.hasErrors = false;
    },
    [sendOtp.rejected.type]: (state, action) => {
      state.OtpData.loading = false;
      state.OtpData.hasErrors = true;
      state.OtpData.message = action.error.message;
    },

    //resend otp on mobileNo
    [reSendOtp.pending.type]: (state) => {
      state.ResendOtpData.loading = true;
    },
    [reSendOtp.fulfilled.type]: (state, action) => {
      state.ResendOtpData.message = action.payload.message;
      state.ResendOtpData.hasErrors = action.payload.error;
      state.ResendOtpData.loading = false;
      state.ResendOtpData.hasErrors = false;
    },
    [reSendOtp.rejected.type]: (state, action) => {
      state.ResendOtpData.loading = false;
      state.ResendOtpData.hasErrors = true;
      state.ResendOtpData.message = action.error.message;
    },

    //verify otp
    [verifyOtp.pending.type]: (state) => {
      state.verifyData.loading = true;
    },
    [verifyOtp.fulfilled.type]: (state, action) => {
      state.verifyData.data = action.payload.data;
      state.verifyData.message = action.payload.message;
      state.verifyData.loading = false;
      state.verifyData.hasErrors = false;
      state.isLogIn = true;
    },
    [verifyOtp.rejected.type]: (state, action) => {
      state.verifyData.loading = false;
      state.verifyData.hasErrors = true;
      state.verifyData.message = action.error.message;
    },

    //Set Student Mpin
    [setStudentMpin.pending.type]: (state) => {
      state.setMpinData.loading = true;
    },
    [setStudentMpin.fulfilled.type]: (state, action) => {
      state.setMpinData.message = action.payload.message;
      state.setMpinData.hasErrors = action.payload.error;
      state.setMpinData.loading = false;
      state.setMpinData.hasErrors = false;
    },
    [setStudentMpin.rejected.type]: (state, action) => {
      state.setMpinData.loading = false;
      state.setMpinData.hasErrors = true;
      state.setMpinData.message = action.error.message;
    },
  }
});

// A selector
export const loginSelector = (state: RootState) => state.login;

export const { clearRemoveMessage, setIsLogIn, setTokenData } =
  loginSlice.actions;

// The reducer
export default loginSlice.reducer;
