import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../../utils/constants/ThemeSetting";
import logo from "../../../assets/images/logo-white.png";
import { toggleCollapsedSideNav } from "src/state/setting/setting.action";
import{
  
  CloseOutlined,
}from "@ant-design/icons"

import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
interface ISlidbarContentPros {
  sidebarCollapsed?: boolean;
  setSidebarCollapsed?: (a: boolean) => void;
}

const SidebarLogo = ({
  sidebarCollapsed,
  setSidebarCollapsed,
}: ISlidbarContentPros) => {
  const navCollapsed = useSelector(({ common }: any) => common.navCollapsed);
  const dispatch = useDispatch();
  const { width, themeType } = useSelector(({ settings }: any) => settings);
  let navStyle = useSelector(({ settings }: any) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
let navigate = useNavigate();

  const path = window.location.pathname;
  return (
    <div className="gx-layout-sider-header">
      {/* {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null} */}
      {path !== '/' && (
        
        <span className="backBtn" onClick={() => navigate("/")}>
        <i className="fa fa-chevron-left"></i>
      </span>
      )}
      <Link to="/" className="cc gx-site-logo">
          
            <img alt="logo2" src={"/assets/images/logo-white.png"} />
          
        </Link>
      
    </div>
  );
};

export default SidebarLogo;
