import { RootState } from "../app.model";
import {
  addFeedBack,
  editFeedBackDataById,
  getFeedBackType,
} from "./feedBack.action";
import { IFeedBackState } from "./feedBack.model";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: IFeedBackState = {
  addFeedBack: {
    loading: false,
    hasErrors: false,
    message: "",
  },
  getFeedBack: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  editFeedBackDataById: {
    loading: false,
    hasErrors: false,
    message: "",
  },
};

export const feedBackSlice = createSlice({
  name: "feed-back",
  initialState,
  reducers: {
    clearRemoveMessage: (state) => {
      state.editFeedBackDataById.message = "";
      state.addFeedBack.message = "";
    },
    clearState: (state) => {
      state.getFeedBack.data = initialState.getFeedBack.data;
    },
  },
  extraReducers: {
    // editFeedBack
    [editFeedBackDataById.pending.type]: (state) => {
      state.editFeedBackDataById.loading = true;
    },
    [editFeedBackDataById.fulfilled.type]: (state, action) => {
      state.editFeedBackDataById.message = action.payload.message;
      state.editFeedBackDataById.loading = false;
      state.editFeedBackDataById.hasErrors = false;
    },
    [editFeedBackDataById.rejected.type]: (state, action) => {
      state.editFeedBackDataById.loading = false;
      state.editFeedBackDataById.hasErrors = true;
      state.editFeedBackDataById.message = action.error.message;
    },

    // get feedBackType
    [getFeedBackType.pending.type]: (state) => {
      state.getFeedBack.loading = true;
    },
    [getFeedBackType.fulfilled.type]: (state, action) => {
      state.getFeedBack.data = action.payload.data;
      state.getFeedBack.message = action.payload.message;
      state.getFeedBack.loading = false;
      state.getFeedBack.hasErrors = false;
    },
    [getFeedBackType.rejected.type]: (state, action) => {
      state.getFeedBack.loading = false;
      state.getFeedBack.hasErrors = true;
      state.getFeedBack.message = action.error.message;
    },

    // add feedBack
    [addFeedBack.pending.type]: (state) => {
      state.addFeedBack.loading = true;
    },
    [addFeedBack.fulfilled.type]: (state, action) => {
      state.addFeedBack.message = action.payload.message;
      state.addFeedBack.loading = false;
      state.addFeedBack.hasErrors = false;
    },
    [addFeedBack.rejected.type]: (state, action) => {
      state.addFeedBack.loading = false;
      state.addFeedBack.hasErrors = true;
      state.addFeedBack.message = action.error.message;
    },
  },
});

export const feedBackSelector = (state: RootState) => state.feedBack;

export const { clearRemoveMessage, clearState } = feedBackSlice.actions;

// The reducer
export default feedBackSlice.reducer;
