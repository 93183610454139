import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Layout } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { updateWindowWidth } from "./state/setting/setting.action";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
} from "./utils/constants/ThemeSetting";
import Topbar from "./components/containers/Topbar";
import { AppDispatch } from "./state/app.model";
import { SidebarCollapseContext } from "./contexts/SidebarCollapseContext";
import Dashboard from "./pages/Dashboard";
import LogIn from "./pages/Login";
import { useAppSelector } from "./state/app.hooks";
import {
  loginSelector,
  setIsLogIn,
  setTokenData,
} from "./state/Login/login.reducer";
import NotFound from "./pages/404NotFound/NotFound";
import StudentDetail from "./pages/StudentDetail";
import "./lib/axios/Interceptor";
import jwt_decode from "jwt-decode";
import store from "./state/store";
import { ILogInStudentPortalERPSide } from "./services/login/login.model";
import CheckVersionModal from "./components/CheckVersion";
import ExamIndex from "./pages/ExamPortal";
import ExamDashboard from "./pages/ExamPortal/Dashboard";
import ExamThankYouPage from "./pages/ExamPortal/ExamThankYouPage";
const { Content } = Layout;

const getContainerClass = (navStyle: string) => {
  switch (navStyle) {
    case NAV_STYLE_DARK_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_DEFAULT_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
      return "gx-container-wrap";
    case NAV_STYLE_BELOW_HEADER:
      return "gx-container-wrap";
    case NAV_STYLE_ABOVE_HEADER:
      return "gx-container-wrap";
    default:
      return "";
  }
};

const getNavStyles = (navStyle: string) => {
  switch (navStyle) {
    // case NAV_STYLE_DEFAULT_HORIZONTAL :
    //   return <HorizontalDefault/>;
    // case NAV_STYLE_DARK_HORIZONTAL :
    //   return <HorizontalDark/>;
    // case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
    //   return <InsideHeader/>;
    // case NAV_STYLE_ABOVE_HEADER :
    //   return <AboveHeader/>;
    // case NAV_STYLE_BELOW_HEADER :
    //   return <BelowHeader/>;
    case NAV_STYLE_FIXED:
      return <Topbar />;
    // case NAV_STYLE_DRAWER :
    //   return <Topbar/>;
    // case NAV_STYLE_MINI_SIDEBAR :
    //   return <Topbar/>;
    // case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
    //   return <NoHeaderNotification/>;
    // case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
    //   return <NoHeaderNotification/>;
    default:
      return null;
  }
};

function App() {
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { navStyle } = useSelector(({ settings }: any) => settings);
  const dispatch = useDispatch<AppDispatch>();
  const loginState = useAppSelector(loginSelector);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(updateWindowWidth(window.innerWidth));
    });
  }, [dispatch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token: string | null = searchParams.get("t");
    if (token) {
      const decode: ILogInStudentPortalERPSide = jwt_decode(token ? token : "");
      dispatch(
        setTokenData({
          mobile_no: decode.mobile_no,
          token: token,
          type: "WEB",
          device_token: "string",
          created_date: decode.iat,
        })
      );
      dispatch(setIsLogIn(true));
      navigate("/");
    }
  }, [window.location.search]);
  const isExamPortal = location.pathname.includes("/exam-portal");
  return (
    <>
      <SidebarCollapseContext.Provider
        value={{
          sidebarCollapsed,
          setSidebarCollapsed,
        }}
      >
        {isExamPortal && (
          <>
          <Routes>
            <Route path="/:id/exam-portal" element={<ExamIndex />} />
          </Routes>
          <Routes>
            <Route path="/exam-portal/:id/dashboard" element={<ExamDashboard />} />
          </Routes>
          <Routes>
            <Route path="/exam-portal/thank-you" element={<ExamThankYouPage />} />
          </Routes>
        </>
        )}
        {loginState.isLogIn === false ? (
          <>
            <Routes>
              <Route path="*" element={<LogIn />} />
            </Routes>
          </>
        ) : (
           !isExamPortal && (
            <Layout className="gx-app-layout">
              <CheckVersionModal />
              {/* <AppSidebar navStyle={navStyle} /> */}
              <Layout>
                {getNavStyles(navStyle)}
                <Content
                  className={`gx-layout-content ${getContainerClass(
                    navStyle
                  )} `}
                >
                  <div className="gx-main-content-wrapper">
                    <Routes>
                      <Route element={<Outlet />}>
                        <Route path="/" element={<Dashboard />} />
                      </Route>
                      <Route element={<Outlet />}>
                        <Route
                          path="/:id/student-detail"
                          element={<StudentDetail />}
                        />
                      </Route>
                      <Route element={<Outlet />}>
                        <Route path="*" element={<NotFound />} />
                      </Route>
                    </Routes>
                  </div>
                </Content>
              </Layout>
            </Layout>
          )
        )}
      </SidebarCollapseContext.Provider>
    </>
  );
}

export default App;
