import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  IAddFeedBack,
  IEditFeedBack,
} from "src/services/feedBack/feedBack.model";
import feedBackService from "src/services/feedBack/feedBack.service";

export const addFeedBack = createAsyncThunk(
  "feed-back/addFeddBack",
  async (data: IAddFeedBack) => {
    return feedBackService.addFeedBack(data);
  }
);

export const getFeedBackType = createAsyncThunk(
  "feed-back/getFeedBackType",
  async () => {
    return feedBackService.getFeedBackType();
  }
);

export const editFeedBackDataById = createAsyncThunk(
  "feed-back/editFeedBackDataById",
  async (data: IEditFeedBack) => {
    return feedBackService.editFeedBackDataById(data);
  }
);
