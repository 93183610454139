import { Tooltip } from "antd";
import moment from "moment";
import { ToWords } from "to-words";
import { AES } from "crypto-js";
import config from "./config";

export const dateFormate = (date: string | Date) => {
  if (date) {
    return moment(date).format("DD-MMM-YYYY");
  }
  return date;
};

export const dateFormateWithTime = (date: string | Date) => {
  if (date) {
    return moment(date).format("DD-MMM-YYYY, h:mm A");
  }
  return date;
};

export const trimObject = (data: any) => {
  var trimmed = JSON.stringify(data, (key, value) => {
    if (typeof value === "string") {
      return value.trim();
    }
    return value;
  });
  return JSON.parse(trimmed);
};

export const isCSV = (name: string) => {
  const reg = /^.*\.(csv|CSV)$/;
  return reg.test(name);
};

export const currencyFormat = (amount: number | string) => {
  if (amount) {
    return amount.toLocaleString("en-IN", { maximumFractionDigits: 2 });
  } else {
    return amount;
  }
};

export const calculateGst = (netAmount: number | string) => {
  let gst;
  if (netAmount) {
    gst = (Number(netAmount) * 18) / 118;
    gst /= 2;
  }
  return Math.trunc(Number(gst));
};

export const GetSortOrder = (prop: any, order: "ASC" | "DESC") => {
  return function (a: any, b: any) {
    if (order === "ASC") {
      if (a[prop]?.toLowerCase() > b[prop]?.toLowerCase()) {
        return 1;
      } else if (a[prop]?.toLowerCase() < b[prop]?.toLowerCase()) {
        return -1;
      }
    } else if (order === "DESC") {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
    }
    return 0;
  };
};
export const GetSortOrderWithoutLowercase = (
  prop: any,
  order: "ASC" | "DESC"
) => {
  return function (a: any, b: any) {
    if (order === "ASC") {
      if (a[prop] > b[prop]) {
        return 1;
      } else if (a[prop] < b[prop]) {
        return -1;
      }
    } else if (order === "DESC") {
      if (a[prop] > b[prop]) {
        return -1;
      } else if (a[prop] < b[prop]) {
        return 1;
      }
    }
    return 0;
  };
};
export const showTooltip = (value: string) => {
  if (value.length > 20) {
    const truncatedValue = value.substring(0, 20) + "...";
    const tooltipValue = value.substring(0);
    return (
      <Tooltip title={tooltipValue}>
        <span>{truncatedValue}</span>
      </Tooltip>
    );
  }
  return <span>{value}</span>;
};

export const CommonData = (arrList: any) => {
  var arrLength = Object.keys(arrList).length;
  // (Also accepts regular objects as input)
  var index: any = {};
  for (var i in arrList) {
    for (var j in arrList[i]) {
      var v = arrList[i][j];
      if (index[v] === undefined) index[v] = {};
      index[v][i] = true; // Mark as present in i input.
    }
  }
  var retv = [];
  for (var i in index) {
    if (Object.keys(index[i]).length == arrLength) retv.push(i);
  }
  return retv;
};

export const toWords = new ToWords({
  localeCode: "en-IN",
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: "Rupee",
      plural: "Rupees",
      symbol: "₹",
      fractionalUnit: {
        name: "Paisa",
        plural: "Paise",
        symbol: "",
      },
    },
  },
});

export const openInNewTab = (url: string) => {
  window.open(url, "_blank");
};

export const suffleArray = (array: any[]) => {
  // Create a copy of the original array to avoid mutating the original
  array = [...array];

  // Fisher-Yates shuffle algorithm
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
    [array[i], array[j]] = [array[j], array[i]]; // Swap elements
  }

  return array;
};

export const encrypt = (data: string) => {
  return encodeURIComponent(AES.encrypt(data, config.secret_key).toString());
};
