import React from "react";
import { Button, Modal } from "antd";
import { IMoreButtonProps } from "./MoreButton.model";

const MoreButtonShow = (props: IMoreButtonProps) => {
  const { text , titleText , textLength = 24} = props;

  const info = (value: string) => {
    Modal.info({
      title: titleText,
      icon : false,
      className:'more-button-modal',
      content: (
        <div
          className="remark-message fixHeight-model"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      ),
      onOk() {},
    });
  };

  return (
    <div>
      <div className="more-text gx-d-flex">
        {titleText && <span style={{fontWeight : '500'}}>{`${titleText} :`}&nbsp;</span>}
        <p className="gx-mr-1 note-text">
           <span
              dangerouslySetInnerHTML={{ __html: text }}
            />
        </p>        
        <Button onClick={() => info(text)}>More</Button>
      </div>
    </div>
  );
};

export default MoreButtonShow;
