import { Button, Form, Input, message } from "antd";
import { useEffect, useRef, useState } from "react";
import ContainerHeader from "../ContainerHeader";
import { useAppSelector } from "src/state/app.hooks";
import {
  clearRemoveMessage,
  loginSelector,
} from "src/state/Login/login.reducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { setStudentMpin } from "src/state/Login/login.action";
import { IMpinProps } from "./Mpin.model";
import { ILogInStudentPortalERPSide } from "src/services/login/login.model";
import jwt_decode from "jwt-decode";

const Mpin = (props: IMpinProps) => {
  const { setClose, show } = props;
  const formRef = useRef<any>();
  const dispatch = useDispatch<AppDispatch>();
  const logInState = useAppSelector(loginSelector);
  const rules = {
    mpin: [{ required: true, message: "Please Enter Mpin!" }],
    mpinNumber: [
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
    ],
  };

  if (show) {
    formRef?.current?.resetFields();
  }

  useEffect(() => {
    if (logInState.setMpinData.message) {
      if (logInState.setMpinData.hasErrors) {
        message.error(logInState.setMpinData.message);
      } else {
        message.success(logInState.setMpinData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [logInState.setMpinData.message]);

  const decode: ILogInStudentPortalERPSide = jwt_decode(
    logInState.verifyData.data && logInState.verifyData.data.token
  );

  const onFinish = (values: { mpin: string[]; confirm: string[] }) => {
    if (
      values.mpin.length ===
      values.confirm.filter((confirm) => confirm !== undefined && confirm !== '').length
    ) {
      const mPin = values.mpin.join("");
      const data = {
        mobile_no: decode.mobile_no,
        mpin: mPin,
      };
      if (data) {
        dispatch(setStudentMpin(data)).then((res) => {
          if (res.payload) {
            setClose(false);
            formRef.current.resetFields();
          } else {
            setClose(true);
          }
        });
      }
    } else {
      message.error("Mpin must be exactly 6 characters long");  
    }
  };

  const handleInputChange = (
    inputIndex: number,
    value: string,
    field: string
  ) => {
    const fieldName = [field, inputIndex];
    formRef?.current?.setFieldsValue({ [fieldName as any]: value });

    if (value && inputIndex < 5) {
      const nextFieldName = [field, inputIndex + 1];
      const nextInput = formRef?.current?.getFieldInstance(nextFieldName);
      nextInput?.focus();
    } else if (!value && inputIndex > 0) {
      const prevFieldName = [field, inputIndex - 1];
      const prevInput = formRef?.current?.getFieldInstance(prevFieldName);
      prevInput?.focus();
    }
  };
  const handleKeyDown = (
    inputIndex: number,
    e: React.KeyboardEvent<HTMLInputElement>,
    field: string
  ) => {
    if (e.key === "Backspace") {
      const value = (e.target as HTMLInputElement).value;
      if (!value && inputIndex > 0) {
        e.preventDefault();
        const prevFieldName = [field, inputIndex - 1];
        const prevInput = formRef?.current?.getFieldInstance(prevFieldName);
        prevInput?.focus();
      } else if (value && inputIndex === 0) {
        e.preventDefault();
        formRef?.current?.setFieldsValue({ [field]: [] });
        const firstInput = formRef?.current?.getFieldInstance([field, 0]);
        firstInput?.focus();
      }
    } else {
      // Allow only numeric characters (0-9)
      if (!/^[0-9]+$/.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  useEffect(() => {
    const firstInput = formRef?.current?.getFieldInstance(["mpin", 0]);
    const value = formRef?.current?.getFieldValue(["mpin", 0]);
    if (!value) {
      firstInput?.focus();
    }
  }, []);

  return (
    <Form
      ref={formRef}
      onFinish={onFinish}
      className="updateMPIN otp-input gx-py-0"
    >
      <ContainerHeader className="gx-text-center" title={"Set Mpin"} />
      <Form.Item
        name="mpin"
        label="Enter Mpin"
        className="gx-mt-3"
        rules={rules.mpin}
      >
        <Input.Group compact>
          {[...Array(6)].map((_, index) => (
            <Form.Item
              key={index}
              name={["mpin", index]}
              noStyle
              rules={[
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "Only Number Are Allowed",
                },
              ]}
            >
              <Input
                className="otp-number"
                type="password"
                maxLength={1}
                style={{ width: "40px", marginRight: "5px" }}
                onChange={(e) =>
                  handleInputChange(index, e.target.value, "mpin")
                }
                onKeyDown={(e) => handleKeyDown(index, e, "mpin")}
              />
            </Form.Item>
          ))}
        </Input.Group>
      </Form.Item>
      <Form.Item
        name="confirm"
        dependencies={["mpin"]}
        label="Confirm Mpin"
        rules={[{ required: true, message: "Please confirm your Mpin" }]}
      >
        <Input.Group compact>
          {[...Array(6)].map((_, index) => (
            <Form.Item
              key={index}
              name={["confirm", index]}
              noStyle
              rules={[
                {
                  pattern: new RegExp(/^[0-9]+$/),
                  message: "Only Number Are Allowed",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("mpin")[index] === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      <div className="notSameMPIN">
                        The two Mpin that you entered do not match!
                      </div>
                    );
                  },
                }),
              ]}
            >
              <Input
                className="otp-number"
                maxLength={1}
                type="password"
                style={{ width: "40px", marginRight: "5px" }}
                onChange={(e) =>
                  handleInputChange(index, e.target.value, "confirm")
                }
                onKeyDown={(e) => handleKeyDown(index, e, "confirm")}
              />
            </Form.Item>
          ))}
        </Input.Group>
      </Form.Item>

      <Form.Item className="gx-text-center">
        <Button type="primary" className="otp-submit" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Mpin;
