import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import { IAddFeedBack, IEditFeedBack, IFeedBackType, IFeesDeatil } from "./feedBack.model";

class FeedBackService {
  getFessDetailsById(id: number): Promise<IApiResponse<IFeesDeatil[]>> {
    throw new Error("Method not implemented.");
  }
  ENDPOINT = config.baseApiApplication + "/feed-back";

  public addFeedBack = async (
    data: IAddFeedBack
  ): Promise<IApiResponse<IAddFeedBack>> => {
    const url = `${this.ENDPOINT}/feed-back`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public getFeedBackType = async (): Promise<IApiResponse<IFeedBackType[]>> => {
    const url = `${this.ENDPOINT}/feedback-type`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  public editFeedBackDataById = async (
    newdata: IEditFeedBack
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/${newdata.id}/feed-back`;
    const data = newdata;
    return request({ url, method: "PATCH", data }).then((res) => {
      return res.data;
    });
  };
}

export default new FeedBackService();
