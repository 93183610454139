import { IContainerHeaderProps } from "./ContainerHeader.model";

const ContainerHeader = (props: IContainerHeaderProps) => {
  const { className, title } = props;
  return (
    <div className="rnw-page-heading">
      <h2 className={className}>{title}</h2>
    </div>
  );
};

export default ContainerHeader;
