import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import { IFeesDetails } from "./fees.model";

class FeesService {
  ENDPOINT = config.baseApiApplication + "/fees";

  public getFessDetailsById = async (
    id: number
  ): Promise<IApiResponse<IFeesDetails[]>> => {
    const url = `${this.ENDPOINT}/${id}/fees-details`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };
}

export default new FeesService();
