import { useState, useEffect } from "react";
import { Iprops } from "./timer.model";

const CountdownTimer = (props: Iprops) => {
  const { minutes,timeOut } = props;
  const [seconds, setSeconds] = useState(minutes * 60);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    }
    if (seconds === 0) {
      timeOut(true);
    }
    return () => clearInterval(interval);
  }, [seconds]);

useEffect(() => {
  setSeconds(minutes * 60);
},[minutes])


  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <div>
      <p className="gx-text-center">{formatTime(seconds)}</p>
    </div>
  );
};

export default CountdownTimer;
