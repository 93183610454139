// ThankYouPage.jsx
import React from "react";
import { Result, Button, Card } from "antd";
import { useNavigate } from "react-router-dom";

const ExamThankYouPage = () => {
  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate("/");
    window.close()
  };
  return (
    <div
      className="ant-layout-content thank-you-card"
    >
      <Card className="ant-card ant-card-bordered">
        <Result
          status="success"
          title="Thank you for completing the exam!"
          extra={[
            <Button type="primary" onClick={handleHomeClick}>
              Back to Home
            </Button>,
          ]}
        />
      </Card>
    </div>
  );
};

export default ExamThankYouPage;
