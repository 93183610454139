import { ISetMpin, IVerify } from "src/components/OtpInput/otpinput.model";
import request from "src/lib/axios/request";
import { INumber } from "src/pages/Login/login.model";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";



class LoginService {
  ENDPOINT = config.baseApiApplication+ "/user";

  public sendOtp = async (
    data: INumber
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/send-otp`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public reSendOtp = async (
    data: INumber
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/resend-otp`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public verifyOtp = async (
    data: IVerify
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/verify-otp`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

  public setStudentMpin = async (
    data: ISetMpin
  ): Promise<IApiResponse<string>> => {
    const url = `${this.ENDPOINT}/create-or-upate-student-mpin`;
    return request({ url, method: "POST", data }).then((res) => {
      return res.data;
    });
  };

}

export default new LoginService ();
