import { Button, Col, Form, Input, Row, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import OtpInput from "src/components/OtpInput";
import CountdownTimer from "src/components/Timer";
import { reSendOtp, sendOtp, verifyOtp } from "src/state/Login/login.action";
import {
  clearRemoveMessage,
  loginSelector,
} from "src/state/Login/login.reducer";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { INumber } from "./login.model";
import {
  IVerify,
  IVerifyWithMpin,
} from "src/components/OtpInput/otpinput.model";

const LogIn = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [form] = Form.useForm();
  const loginState = useAppSelector(loginSelector);
  const [number, setNumber] = useState<INumber>({ mobile_no: "" });
  const [show, setShow] = useState<boolean>(false);
  const [mpin, setMpin] = useState<boolean>(false);
  const [showTimer, setShowTimer] = useState<boolean>(false);
  const [time, setTime] = useState<number>(5);

  const rules = {
    mobile_no: [
      { required: true, message: "Please Enter Mobile Number" },
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
      { min: 10 },
      { max: 10 },
    ],
    mpin: [
      { required: true, message: "Please Enter Mpin" },
      { pattern: new RegExp(/^[0-9]+$/), message: "only Number Are Allowed" },
      { min: 6 },
      { max: 6 },
    ],
  };

  useEffect(() => {
    if (loginState.ResendOtpData.message) {
      if (loginState.ResendOtpData.hasErrors) {
        message.error(loginState.ResendOtpData.message);
      } else {
        message.success(loginState.ResendOtpData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [loginState.ResendOtpData.message]);

  useEffect(() => {
    if (loginState.OtpData.message) {
      if (loginState.OtpData.hasErrors) {
        message.error(loginState.OtpData.message);
      } else {
        message.success(loginState.OtpData.message);
        setShow(true);
        setTime(5);
      }
      dispatch(clearRemoveMessage());
    }
  }, [loginState.OtpData.message]);

  useEffect(() => {
    if (loginState.verifyData.message) {
      if (loginState.verifyData.hasErrors) {
        message.error(loginState.verifyData.message);
      } else {
        message.success(loginState.verifyData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [loginState.verifyData.message]);

  const onFinish = (value: INumber) => {
    if (value.mpin) {
      const verifyOtpPayload: IVerifyWithMpin = {
        mobile_no: value?.mobile_no,
        otp: value.mpin,
        device_token: "string",
        type: "WEB",
        auth: "MPIN",
      };
      dispatch(verifyOtp(verifyOtpPayload));
    } else {
      setNumber(value);
      dispatch(sendOtp(value));
    }
  };

  const timeoutValue = (value: boolean) => {
    setTime(0);
    setShowTimer(value);
  };

  const handleReSendOtp = () => {
    setTime(5);
    setShowTimer(false);
    dispatch(reSendOtp(number));
  };

  const handleChangeNumber = () => {
    setShow(false);
    setShowTimer(false);
    form.resetFields();
  };

  return (
    <Row>
      <Col xs={24}>
        <div className="login-box">
          <span className="login-pattern">
            <img
              alt="logo1"
              className="login-logo"
              src={"/assets/images/logo-white.png"}
            />
          </span>
          {show ? (
            ""
          ) : (
            <div className="mobileEnter">
              <Form form={form} onFinish={onFinish}>
                <Form.Item
                  label="Mobile Number"
                  name="mobile_no"
                  rules={rules.mobile_no}
                >
                  <Input
                    size="large"
                    placeholder="Please Enter Mobile Number"
                  />
                </Form.Item>
                {mpin ? (
                  <Form.Item label="Mpin" name="mpin" rules={rules.mpin}>
                    <Input type="password" size="large" placeholder="Please Enter Mpin" />
                  </Form.Item>
                ) : (
                  <Button type="primary" htmlType="submit" className="gx-my-0">
                    Login With OTP
                  </Button>
                )}

                {mpin ? (
                  <>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="gx-my-0"
                    >
                      Submit
                    </Button>
                    <span
                      className="change-number gx-mt-2"
                      onClick={() => setMpin(false)}
                    >
                      Back
                    </span>
                  </>
                ) : (
                  ""
                )}
              </Form>
              {mpin ? (
                ""
              ) : (
                <Button
                  type="ghost"
                  id="mpin"
                  onClick={() => setMpin(true)}
                  className="gx-my-0"
                >
                  Login With MPIN
                </Button>
              )}
            </div>
          )}

          {show && (
            <div style={{padding:"50px 0px 10px"}}>
              <h1 className="gx-text-center ">Enter OTP</h1>
              <p className="gx-text-center">
                Please enter the otp sent on {number.mobile_no}
              </p>
              <OtpInput mobileNumber={number} />

              <Button
                onClick={handleReSendOtp}
                className="otp-submit"
                disabled={showTimer ? false : true}
              >
                Re-Send OTP
              </Button>

              {showTimer ? (
                ""
              ) : (
                <CountdownTimer
                  minutes={time}
                  timeOut={(value: boolean) => timeoutValue(value)}
                />
              )}

              <span
                className="change-number gx-pb-0  gx-pb-md-3"
                onClick={handleChangeNumber}
              >
                Change Mobile Number
              </span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default LogIn;
