import { combineReducers } from "@reduxjs/toolkit";
import CommonReducer from "./common/common.reducer";
import SettingsReducer from "./setting/setting.reducer";
import StudentReducer from "./student/student.reducer";
import FeesReducer from "./feesDetails/fees.reducer";
import AttendanceReducer from "./attendance/attendance.reducer";
import FeedBackReducer from "./feedBack/feedBack.reducer";
import courseReducer from "./course/course.reducer";
import batchReducer from "./batch/batch.reducer";
import loginReducer from "./Login/login.reducer";
import studentExamReducer from "./exam/exam.reducer";

export const combinedReducer = combineReducers({
  common: CommonReducer,
  settings: SettingsReducer,
  student: StudentReducer,
  fees: FeesReducer,
  attendance: AttendanceReducer,
  feedBack: FeedBackReducer,
  course: courseReducer,
  batch: batchReducer,
  login:loginReducer,
  studentExam : studentExamReducer
});

export const rootReducer = (state: any, action: any) => {
  return combinedReducer(state, action);
};
