import { createSlice } from "@reduxjs/toolkit";
import { getBatchByAdmissionId } from "./batch.action";
import { RootState } from "../app.model";
import { IBatchState } from "./batch.model";

export const initialState: IBatchState = {
  getBatchByAdmissionId: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    clearState: (state) => {
      state.getBatchByAdmissionId.data =
        initialState.getBatchByAdmissionId.data;
    },
  },

  extraReducers: {
    // get batch by admission id

    [getBatchByAdmissionId.pending.type]: (state) => {
      state.getBatchByAdmissionId.loading = true;
    },
    [getBatchByAdmissionId.fulfilled.type]: (state, action) => {
      state.getBatchByAdmissionId.data = action.payload.data;
      state.getBatchByAdmissionId.message = action.payload.message;
      state.getBatchByAdmissionId.loading = false;
      state.getBatchByAdmissionId.hasErrors = false;
    },
    [getBatchByAdmissionId.rejected.type]: (state, action) => {
      state.getBatchByAdmissionId.loading = false;
      state.getBatchByAdmissionId.hasErrors = true;
      state.getBatchByAdmissionId.message = action.error.message;
    },
  },
});

// A selector
export const batchSelector = (state: RootState) => state.batch;

export const { clearState } = batchSlice.actions;

// The reducer
export default batchSlice.reducer;
