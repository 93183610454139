import { Avatar, Card, Col, Collapse, Row, Image, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { IStudentList } from "src/services/student/student.model";
import { useAppSelector } from "src/state/app.hooks";
import { AppDispatch } from "src/state/app.model";
import { searchStudentData } from "src/state/student/student.action";
import { clearState, studentSelector } from "src/state/student/student.reducer";
import { config } from "src/utils";
import { UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  clearRemoveMessage,
  loginSelector,
} from "src/state/Login/login.reducer";

const Dashboard = () => {
  const [studentData, setStudentData] = useState<IStudentList[]>();
  const loginState = useAppSelector(loginSelector);
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  const studentState = useAppSelector(studentSelector);

  useEffect(() => {
    dispatch(searchStudentData());
  }, []);

  useEffect(() => {
    if (studentState.studentData.data) {
      setStudentData(studentState.studentData.data);
    }
  }, [studentState.studentData.data]);

  useEffect(() => {
    if (studentState.studentData.message) {
      if (studentState.studentData.hasErrors) {
        message.error(studentState.studentData.message);
        dispatch(clearState());
      }
    }
  }, [studentState.studentData.message]);

  useEffect(() => {
    if (loginState.verifyData.message) {
      if (loginState.verifyData.hasErrors) {
        message.error(loginState.verifyData.message);
      } else {
        message.success(loginState.verifyData.message);
      }
      dispatch(clearRemoveMessage());
    }
  }, [loginState.verifyData.message]);

  return (
    <>
      <div>
        <Row gutter={15}>
          {studentData &&
            studentData?.map((x) => (
              <>
                <Col
                  xs={24}
                  md={12}
                  lg={12}
                  xxl={8}
                  onClick={() =>
                    navigate(`/${x?.id}/student-detail`, {
                      replace: true,
                    })
                  }
                >
                  <Card
                    className="dashboard-card"
                    style={{ cursor: "pointer" }}
                  >
                    <Row gutter={15} className="gx-align-items-center">
                      <Col
                        xs={8}
                        sm={6}
                        md={8}
                        lg={6}
                        className="gx-text-center"
                      >
                        <div className="card-profile">
                          {x?.admission_documents?.[0]?.photos ? (
                            <Avatar
                              src={
                                <Image
                                  src={
                                    config.bucket_name +
                                    "/" +
                                    x?.admission_documents?.[0]?.photos
                                  }
                                />
                              }
                              onClick={(event) =>
                                event && event.stopPropagation()
                              }
                            />
                          ) : (
                            <Avatar
                              className="avtar-default"
                              icon={<UserOutlined />}
                            />
                          )}
                        </div>
                      </Col>
                      <Col xs={14} className="gx-d-sm-none">
                        <div className="profile-details">
                          <h5 className="usernameMobile">{`${x?.first_name} ${x?.last_name}`}</h5>
                          <span>GR Id : {x.gr_id}</span>
                        </div>
                      </Col>
                      <Col xs={24} sm={18} md={16} lg={18}>
                        <div className="profile-details mobile-profile">
                          <Row>
                            <Col
                              xs={12}
                              sm={24}
                              md={24}
                              xl={9}
                              className="gx-d-none gx-d-sm-block gx-mb-sm-2"
                            >
                              <label>Name</label>
                              <h5>{`${x.first_name} ${x.last_name}`}</h5>
                            </Col>
                            <Col xs={24} sm={12} md={16} xl={9}>
                              <label>Enrollment No.</label>
                              <h5>{x?.enrollment_number}</h5>
                            </Col>
                            <Col
                              xs={24}
                              sm={12}
                              md={8}
                              xl={6}
                              className="gx-d-none gx-d-sm-block"
                            >
                              <label>GR ID</label>
                              <h5>{x?.gr_id}</h5>
                            </Col>
                            <Col xs={24} className="gx-mt-sm-2">
                              <label>Course</label>
                              {x.course_category === "SINGLE" ? (
                                <h5 className="gx-mb-0">
                                  {x?.admission_subcourses[0]?.subcourse?.name}
                                </h5>
                              ) : (
                                <h5 className="gx-mb-0">{x?.package?.name}</h5>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </>
            ))}
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
