import { RootState } from "../app.model";
import { createSlice } from "@reduxjs/toolkit";
import { IFeesState } from "./fees.model";
import { getFessDetailsById } from "./fees.action";

export const initialState: IFeesState = {
  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const feesSlice = createSlice({
  name: "fees",
  initialState,
  reducers: {
    clearState: (state) => {
      state.getById.data = initialState.getById.data;
    },
  },
  extraReducers: {
    // getById admissionDetails
    [getFessDetailsById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getFessDetailsById.fulfilled.type]: (state, action) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getFessDetailsById.rejected.type]: (state, action) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },
  },
});

export const feesSelector = (state: RootState) => state.fees;

export const { clearState } = feesSlice.actions;

// The reducer
export default feesSlice.reducer;
