import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import request from "src/lib/axios/request";
import { IAttendanceData } from "./attendance.model";

class AttendanceService {
  ENDPOINT = config.baseApiApplication + "/attendance";

  public getAttendanceById = async (
    id: number
  ): Promise<IApiResponse<IAttendanceData>> => {
    const url = `${this.ENDPOINT}/${id}/batch-student-shining-sheet-attendance`;
    return request({ url, method: "GET", params: id }).then((res) => {
      return res.data;
    });
  };
}

export default new AttendanceService();
