import request from "src/lib/axios/request";
import { config } from "src/utils";
import { IApiResponse } from "src/utils/models/common";
import {
  getBatchByAdmissionIdResponse,
  getSigningSheetByBatchIdResponse,
} from "./batch.model";
import { ISubmitFormData } from "src/pages/StudentDetail/SubmissionForm/SubmissionForm.model";

class BatchService {
  static ENDPOINT = config.baseApiApplication + "/batch";

  static getBatchByAdmissionId = async (
    admission_id: number
  ): Promise<IApiResponse<getBatchByAdmissionIdResponse[]>> => {
    const url = `${BatchService.ENDPOINT}/${admission_id}/batches`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  static getSigningSheetByBatchId = async (
    batch_id: number
  ): Promise<IApiResponse<getSigningSheetByBatchIdResponse[]>> => {
    const url = `${BatchService.ENDPOINT}/${batch_id}/shining-sheet-topic`;
    return request({ url, method: "GET" }).then((res) => {
      return res.data;
    });
  };

  static updateStudentSubmissionLink = async (
    studentData: ISubmitFormData
  ): Promise<IApiResponse<any>> => {
    const url = `${BatchService.ENDPOINT}/${studentData?.id}/student-submission-link`;
    const {id , ...rest} = studentData
    return request({ url, method: "PATCH" , data:rest }).then((res) => {
      return res.data;
    });
  };
}

export default  BatchService;
