import { Avatar, Popover } from "antd";
import {  useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/state/app.model";
import { loginSelector, setIsLogIn } from "src/state/Login/login.reducer";
import { useAppSelector } from "src/state/app.hooks";
import ModalComponent from "src/components/Modal";
import {  useState } from "react";
import Mpin from "src/components/Mpin";
import jwt_decode from "jwt-decode";
import { ILogInStudentPortalERPSide } from "src/services/login/login.model";

const UserProfile = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const userState = useAppSelector(loginSelector);
  const [show, setShow] = useState<boolean>(false);

  const onLogout = async () => {
    dispatch(setIsLogIn(false));
    navigate("/", { replace: true });
  };

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li onClick={() => setShow(true)}>Set Mpin</li>
      <li onClick={onLogout}>Logout</li>
    </ul>
  );

  const decode: ILogInStudentPortalERPSide = jwt_decode(
    userState.verifyData.data && userState.verifyData.data.token
  );

  return (
    <div className="gx-avatar-row user-profile">
      <Popover
        placement="bottomRight"
        content={userMenuOptions}
        getPopupContainer={(parent) => parent.parentElement as HTMLElement}
      >
        <div>
          <Avatar
            src={"https://via.placeholder.com/150"}
            className="gx-size-40 gx-pointer gx-mr-2"
            alt=""
          />
          <span className="gx-avatar-name">{decode?.mobile_no}</span>
        </div>
        <i className="icon icon-chevron-down gx-fs-xxs gx-ml-3" />
      </Popover>

      <ModalComponent
        component={
          <Mpin setClose={(value) => setShow(value)} show={show && show} />
        }
        showModal={show}
        onCancel={() => setShow(false)}
        footer={false}
        width={450}
      ></ModalComponent>
    </div>
  );
};

export default UserProfile;
