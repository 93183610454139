export enum StoragePath {
  DEFAULT = "/",
  ADMISSION_DOCUMENTS_ASSETS = "AdmissionDocumentsAssets",
  BRANCH_ASSETS = "BranchAssets",
}

export enum InstallmentStatusType {
  PAID = "PAID",
  UNPAID = "UNPAID",
  ADVANCE_PAID = "ADVANCE_PAID",
}

export enum Configurations {
  MIN_ADVANCE_PAYMENT_CONCESSION_LIMIT = "MIN_ADVANCE_PAYMENT_CONCESSION_LIMIT",
  ADVANCE_PAYMENT_CONCESSION = "ADVANCE_PAYMENT_CONCESSION",
}

export enum AdmisionStatusType {
  ONGOING = "ONGOING",
  PENDING = "PENDING",
  HOLD = "HOLD",
  TERMINATED = "TERMINATED",
  TRANSFER = "TRANSFER",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}

export enum CourseCategoryStatusType {
  PACKAGE = "PACKAGE",
  SINGLE = "SINGLE",
}

export enum AdmisionTransferStatusType {
  INITIALIZE = "INITIALIZE",
  ABOT = "ABOT",
  ACCEPT = "ACCEPT",
  REJECT = "REJECT",
}

export enum PayementModeType {
  CASH = "CASH",
  CHEQUE = "CHEQUE",
  DD = "DD",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  ONLINE_PAYMENT = "ONLINE_PAYMENT",
  NEFT_IMPS = "NEFT/IMPS",
  PAYTM = "PAYTM",
  BANK_DEPOSIT = "BANK_DEPOSIT_(CASH)",
  CAPITAL_FLOA = "CAPITAL_FLOAT_(EMI)",
  GOOGLE_PAY = "GOOGLE_PAY",
  PHONE_PAY = "PHONE_PAY",
  BAJAJ_FINSERV = "BAJAJ_FINSERV_(EMI)",
  BHIM_UPI = "BHIM UPI_(INDIA)",
  INSTAMOJO = "INSTAMOJO",
  PAYPAL = "PAYPAL",
  RAZORPAY = "RAZORPAY",
}

export enum FeeTypes {
  TUITION = "TUITION",
  MATERIAL = "MATERIAL",
  REGISTRATION = "REGISTRATION",
}

export enum AvailabilityType {
  FULL_TIME = "FULL_TIME",
  PART_TIME = "PART_TIME",
  VISITOR = "VISITOR",
}
export class Common {
  static Actions = class Actions {
    public static readonly CAN_VIEW = "VIEW";
    public static readonly CAN_DELETE = "DELETE";
    public static readonly CAN_UPDATE = "UPDATE";
    public static readonly CAN_ADD = "ADD";
    public static readonly CAN_ASSIGN_PERMISSION = "ASSIGN_PERMISSION";
    public static readonly ADMISSION_BASIC_INFO = "ADMISSION_BASIC_INFO";
  };

  static Modules = class Modules {
    public static readonly DASHBOARD = Object.freeze({
      DASHBOARD: "DASHBOARD",
    });

    public static readonly USER_CONFIGURATION = Object.freeze({
      USER_CONFIGURATION: "USER_CONFIGURATION",
      USERS: "USERS_USERS",
      ROLES: "USERS_ROLES",
    });

    public static readonly MASTER = Object.freeze({
      MASTER: "MASTER",
      ZONES: "MASTER_ZONES",
      BRANCHES: "MASTER_BRANCHES",
      DEPARTMENTS: "MASTER_DEPARTMENTS",
      SUBDEPARTMENTS: "MASTER_SUBDEPARTMENTS",
      COURSES: "MASTER_COURSES",
      SUBCOURSES: "MASTER_SUBCOURSES",
      PACKAGES: "MASTER_PACKAGES",
      BATCHES: "MASTER_BATCHES",
      BATCH_STUDENT_VIEW: "BATCH_STUDENT_VIEW",
      SUBCOURSE_TOPIC: "MASTER_SUBCOURSE_TOPIC",
      TEMPLATE_SHINING_SHEET: "MASTER_TEMPLATE_SHINING_SHEET",
      CITY: "MASTER_CITY",
      AREA: "MASTER_AREA",
    });

    public static readonly ADMISSION = Object.freeze({
      ADMISSION: "ADMISSION",
      ADD_ADMISSION: "ADMISSION_ADD_ADMISSION",
      VIEW_ADMISSION: "ADMISSION_VIEW_ADMISSION",
      TRANSFER_ADMISSION: "ADMISSION_TRANSFER_ADMISSION",
      OVERDUE_INCOME: "ADMISSION_OVERDUE_INCOME",
      OUTSTANDING_INCOME: "ADMISSION_OUTSTANDING_INCOME",
      INCOME: "INCOME",
      ADMISSION_BASIC_INFO: "ADMISSION_BASIC_INFO",
      ADMISSION_COURSE_INFO: "ADMISSION_COURSE_INFO",
      ADMISSION_COURSE_BATCH_ASSIGN: "ADMISSION_COURSE_BATCH_ASSIGN",
      ADMISSION_COURSE_AS_COMPLETED: "ADMISSION_COURSE_AS_COMPLETED",
      ADMISSION_PAYMENT_INSTALLMENTS_DETAILS:
        "ADMISSION_PAYMENT_INSTALLMENTS_DETAILS",
      ADMISSION_STATUS_CANCELLED_UPDATE: "ADMISSION_STATUS_CANCELLED_UPDATE",
      ADMISSION_STATUS_MARK_TERMINATED_UPDATE:
        "ADMISSION_STATUS_MARK_TERMINATED_UPDATE",
      ADMISSION_STATUS_HOLD_UPDATE: "ADMISSION_STATUS_HOLD_UPDATE",
      ADMISSION_COURSE_MODIFICATION: "ADMISSION_COURSE_MODIFICATION",
      ADMISSION_EDUCATION_DETAILS_INFO: "ADMISSION_EDUCATION_DETAILS_INFO",
      ADMISSION_POSTAL_COMMUNICATION_INFO:
        "ADMISSION_POSTAL_COMMUNICATION_INFO",
      ADMISSION_PARENT_DETAILS_INFO: "ADMISSION_PARENT_DETAILS_INFO",
      ADMISSION_STATUS_HOLD_OVER_VIEW: "ADMISSION_STATUS_HOLD_OVER_VIEW",
      ADMISSION_DOCUMENTS_DETAILS: "ADMISSION_DOCUMENTS_DETAILS",
      ADMISSION_REMARKS: "ADMISSION_REMARKS",
      ADMISSION_CHEQUE_LIST: "ADMISSION_CHEQUE_LIST",
      ADMISSION_CHEQUE_COMMENT_ADD: "ADMISSION_CHEQUE_COMMENT_ADD",
      ADMISSION_COURSE_ADMISSION_LETTER: "ADMISSION_COURSE_ADMISSION_LETTER",
      ADMISSION_MISSING_ADMISSION: "ADMISSION_MISSING_ADMISSION",
      ADMISSION_FEES_LETTER: "ADMISSION_FEES_LETTER",
      ADMISSION_INSTALLMENTS_REPORT: "ADMISSION_INSTALLMENTS_REPORT",
      ADMISSION_INSTALLMENT_MODIFY: "ADMISSION_INSTALLMENT_MODIFY",
    });

    public static readonly EXPENSE = Object.freeze({
      EXPENSE: "EXPENSE",
      CATEGORIES: "EXPENSE_CATEGORIES",
      SUBCATEGORIES: "EXPENSE_SUBCATEGORIES",
      EXPENSE_MASTER: "EXPENSE_MASTER",
      EXPENSE_EXPENSE: "EXPENSE_EXPENSE",
    });

    public static readonly ACADEMIC = Object.freeze({
      ACADEMIC: "ACADEMIC",
      ACADEMIC_USER_BATCH: "ACADEMIC_USER_BATCH",
      ACADEMIC_USER_BATCH_STUDENT_VIEW: "ACADEMIC_USER_BATCH_STUDENT_VIEW",
      ACADEMIC_USER_BATCH_ATTENDANCE_VIEW:
        "ACADEMIC_USER_BATCH_ATTENDANCE_VIEW",
      ACADEMIC_USER_BATCH_STUDENT_SIGNING_SHEET_VIEW:
        "ACADEMIC_USER_BATCH_STUDENT_SIGNING_SHEET_VIEW",
      ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW:
        "ACADEMIC_USER_BATCH_FACULTY_SIGNING_SHEET_VIEW",
    });
  };
}
export enum UserBatchStatusType {
  ONGOING = "ONGOING",
  UP_COMING = "UP_COMING",
  ON_HOLD = "ON_HOLD",
  ON_VACATION = "ON_VACATION",
  CANCELLED = "CANCELLED",
  COMPLETED = "COMPLETED",
}

export enum BranchInOutType {
  BRANCHIN = "1",
  BRANCHOUT = "2",
}

export enum Admission_Installment_Cheque_Type {
  TO_BE_DEPOSIT = "TO_BE_DEPOSIT",
  TO_BE_COLLECTED = "TO_BE_COLLECTED",
  TO_BE_BOUNCE = "TO_BE_BOUNCE",
  TO_BE_CLEARED = "TO_BE_CLEARED",
  TO_BE_CANCELED = "TO_BE_CANCELED",
}

export enum ActionType {
  CREATE = "ADD",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  VIEW = "VIEW",
}
export enum PresentAndAbsentType {
  Present = "P",
  Absent = "A",
}

export enum FeedBackType {
  A = "A",
  B = "B",
  C = "C",
  D = "D",
}
export enum RoleType {
  SUPER_ADMIN = "SUPER_ADMIN",
  ADMIN = "ADMIN",
  FACULTY_HEAD = "FACULTY_HEAD",
  FACULTY = "FACULTY",
  MANAGER = "MANAGER",
}

export enum ZoneType {
  PRIVATE = "PRIVATE",
}

export enum TopicType {
  LECTURE = "LECTURE",
  PROJECT = "PROJECT",
  VIVA = "VIVA",
  EXAM_PRACTICAL = "EXAM_PRACTICAL",
  EXAM_THEORY = "EXAM_THEORY",
}

export const placementPortalRegistrationUrl =
  "https://placement.rnwmultimedia.com/register/student";

export enum AdmissionSubCourseStatus {
  ONGOING = "ONGOING",
  PENDING = "PENDING",
  COMPLETED = "COMPLETED",
  CANCELLED = "CANCELLED",
  UP_COMING = "UP_COMING",
  ON_HOLD = "ON_HOLD",
}

export enum AnswerType {
  URL = "URL",
  UPLOAD_FILE = "UPLOAD FILE",
}
