import { RootState } from "../app.model";
import { createSlice } from "@reduxjs/toolkit";
import { getAttendanceById } from "./attendance.action";
import { IAttendanceState } from "./attendance.model";

export const initialState: IAttendanceState = {
  getById: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
};

export const attendanceSlice = createSlice({
  name: "attendance",
  initialState,
  reducers: {
    clearState: (state) => {
      state.getById.data = initialState.getById.data;
    },
  },
  extraReducers: {
    // getById admissionDetails
    [getAttendanceById.pending.type]: (state) => {
      state.getById.loading = true;
    },
    [getAttendanceById.fulfilled.type]: (state, action) => {
      state.getById.data = action.payload.data;
      state.getById.message = action.payload.message;
      state.getById.loading = false;
      state.getById.hasErrors = false;
    },
    [getAttendanceById.rejected.type]: (state, action) => {
      state.getById.loading = false;
      state.getById.hasErrors = true;
      state.getById.message = action.error.message;
    },
  },
});

export const attendanceSelector = (state: RootState) => state.attendance;

export const { clearState } = attendanceSlice.actions;

// The reducer
export default attendanceSlice.reducer;
