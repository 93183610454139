import { message } from "antd";
import request from "./request";
import { setIsLogIn } from "src/state/Login/login.reducer";
import store from "../../state/store";

request.interceptors.request.use(
  async (config) => {
    const reduxData = store.getState();
    const verifyState = reduxData.login;

    let idleTimeout: NodeJS.Timeout;

    const logoutUser = () => {
      store.dispatch(setIsLogIn(false));
    };

    const resetIdleTimeout = () => {
      clearTimeout(idleTimeout);
      //Set Time for 10 hours from 30 minutes due to exam-portal
      idleTimeout = setTimeout(logoutUser, 10 * 60 * 60 * 1000); // 10 hours
    };

    resetIdleTimeout();
    window.addEventListener("mousemove", resetIdleTimeout);
    window.addEventListener("keydown", resetIdleTimeout);

    if (verifyState.verifyData.data.token) {
      const accessToken = `Bearer ${verifyState.verifyData.data.token}`;
      config.headers && (config.headers["Authorization"] = accessToken);
    }
    if (config.headers && config.headers["no-auth"]) {
      delete config.headers["no-auth"];
      delete config.headers["Authorization"];
    }
    return config;
  },
  (error) => {
    alert(error);
    Promise.reject(error);
  }
);

request.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    if (status && status !== 200) {
      return Promise.reject(new Error(data.message || "Error"));
    } else {
      return response;
    }
  },
  (error) => {
    const { response } = error;

    if (!response) {
      message.error("Please check your internet connection.");
    }

    let e = Array.isArray(response?.data.message)
      ? response.data.message.join(" ")
      : response?.data.message;

    if (response.status === 500) {
      message.error(e);
    }
    if (response.status === 401) {
      store.dispatch(setIsLogIn(false));
    }
    return Promise.reject(e);
  }
);
