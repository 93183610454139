import { RootState } from "../app.model";
import { getExamPaperDetails, getExamPaperDetailsByExamPaperId, getExamResultDetails, submitExamPaperStudentAnswer } from "./exam.action";
import { IStudentState } from "./exam.model";
import { createSlice } from "@reduxjs/toolkit";

export const initialState: IStudentState = {
  examPaperData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  examQuestionsAndAnswerData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: [],
  },
  submitQuestionAnswerStudentData: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {
      examAttendanceUpdated: false,
    },
  },
  getExamResultDetails: {
    loading: false,
    hasErrors: false,
    message: "",
    data: {},
  },
};

export const studentExamSlice = createSlice({
  name: "studentExam",
  initialState,
  reducers: {
    clearState: (state) => {
      state.examPaperData.data = initialState.examPaperData.data;
      state.examPaperData.message = '';
      state.getExamResultDetails.message = '';
    },
  },
  extraReducers: {
    // examPaperData
    [getExamPaperDetails.pending.type]: (state) => {
      state.examPaperData.loading = true;
    },
    [getExamPaperDetails.fulfilled.type]: (state, action) => {
      state.examPaperData.data = action.payload.data;
      state.examPaperData.message = action.payload.message;
      state.examPaperData.loading = false;
      state.examPaperData.hasErrors = false;
    },
    [getExamPaperDetails.rejected.type]: (state, action) => {
      state.examPaperData.loading = false;
      state.examPaperData.hasErrors = true;
      state.examPaperData.message = action.error.message;
    },

    // examQuestionsAndAnswerData
    [getExamPaperDetailsByExamPaperId.pending.type]: (state) => {
      state.examQuestionsAndAnswerData.loading = true;
    },
    [getExamPaperDetailsByExamPaperId.fulfilled.type]: (state, action) => {
      state.examQuestionsAndAnswerData.data = action.payload.data;
      state.examQuestionsAndAnswerData.message = action.payload.message;
      state.examQuestionsAndAnswerData.loading = false;
      state.examQuestionsAndAnswerData.hasErrors = false;
    },
    [getExamPaperDetailsByExamPaperId.rejected.type]: (state, action) => {
      state.examQuestionsAndAnswerData.loading = false;
      state.examQuestionsAndAnswerData.hasErrors = true;
      state.examQuestionsAndAnswerData.message = action.error.message;
    },

    [submitExamPaperStudentAnswer.pending.type]: (state) => {
      state.submitQuestionAnswerStudentData.loading = true;
    },
    [submitExamPaperStudentAnswer.fulfilled.type]: (state, action) => {
      state.submitQuestionAnswerStudentData.data = action.payload.data;
      state.submitQuestionAnswerStudentData.message = action.payload.message;
      state.submitQuestionAnswerStudentData.loading = false;
      state.submitQuestionAnswerStudentData.hasErrors = false;
    },
    [submitExamPaperStudentAnswer.rejected.type]: (state, action) => {
      state.submitQuestionAnswerStudentData.loading = false;
      state.submitQuestionAnswerStudentData.hasErrors = true;
      state.submitQuestionAnswerStudentData.message = action.error.message;
    },
    
    [getExamResultDetails.pending.type]: (state) => {
      state.getExamResultDetails.loading = true;
    },
    [getExamResultDetails.fulfilled.type]: (state, action) => {
      state.getExamResultDetails.data = action.payload.data;
      state.getExamResultDetails.message = action.payload.message;
      state.getExamResultDetails.loading = false;
      state.getExamResultDetails.hasErrors = false;
    },
    [getExamResultDetails.rejected.type]: (state, action) => {
      state.getExamResultDetails.loading = false;
      state.getExamResultDetails.hasErrors = true;
      state.getExamResultDetails.message = action.error.message;
    },
  },
});

export const studentExamSelector = (state: RootState) => state.studentExam;

export const { clearState } = studentExamSlice.actions;

// The reducer
export default studentExamSlice.reducer;
