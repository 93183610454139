import { createAsyncThunk } from "@reduxjs/toolkit";
import { ISubmitFormData } from "src/pages/StudentDetail/SubmissionForm/SubmissionForm.model";
import batchService from "src/services/Batch/batch.service";

export const getBatchByAdmissionId = createAsyncThunk(
  "batches/getBatchByAdmissionId",
  async (admission_id: number) => {
    return batchService.getBatchByAdmissionId(admission_id);
  }
);

export const getSigningSheetByBatchId = createAsyncThunk(
  "batches/getSigningSheetByBatchId",
  async (batch_id: number) => {
    return batchService.getSigningSheetByBatchId(batch_id);
  }
);

export const updateStudentSubmissionLink = createAsyncThunk(
  "batches/updateStudentSubmissionLink",
  async (data: ISubmitFormData) => {
    return batchService.updateStudentSubmissionLink(data);
  }
);
